import {
    HttpErrorResponse,
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { LoginResponse } from 'src/app/auth/models/login-response';
import { LocalStorageService } from './localstorage.service';
import { NgxPermissionsService } from 'ngx-permissions';
import { AuthService } from 'src/app/auth/services/auth.service';


/** Pass untouched request through to the next request handler. */
@Injectable({ providedIn: 'root' })
export class InterceptorService implements HttpInterceptor {
    constructor(
        private router: Router,
        private localStorageService: LocalStorageService,
        private messageService: MessageService,
        private permissionService:NgxPermissionsService,
        private authService:AuthService
    ) { }
    intercept(
        req: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        let user: LoginResponse | null = this.localStorageService.getUserData();
        if (user) {
            req = req.clone({
                headers: req.headers.set(
                    'Authorization',
                    'Bearer ' + user.accessToken
                )
            });
        }

        return next.handle(req).pipe(
            catchError((error: HttpErrorResponse): Observable<any> => {
                if (error && (error as HttpErrorResponse).status === 401) {
                    this.localStorageService.clear();
                    this.permissionService.flushPermissions();
                    this.authService.loggedInUser.next(null);
                    this.router.navigate(['/auth/login']);
                }
                else if(error && error.error.message){
                    this.messageService.add({severity:'error', summary: 'Hata!', detail: error.error.message});
                }

                return throwError(error);
            })
        );
    }
}
