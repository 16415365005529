import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { share } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
const baseUrl = `${environment.apiUrl}`;

@Injectable({
    providedIn: 'root'
})
export class BaseCrudService {

    constructor(private http: HttpClient) { }

    get(path: string, params?: { [param: string]: string | string[]; }): Observable<any> {
        return new Observable<any>((observer) => {
            this.http.get(`${baseUrl}${path}`, { params }).subscribe((res: any) => {
                observer.next(res?.data);
            });
        }).pipe(share());
    }

    post(path: string, body: any | null, options?: any): Observable<any> {
        return new Observable<any>((observer) => {
            this.http.post(`${baseUrl}${path}`, body, options).subscribe((res: any) => {
                observer.next(res?.data);
            });
        }).pipe(share());
    }

    patch(path: string, body: any | null, options?: any) {
        return new Observable<any>((observer) => {
            this.http.patch(`${baseUrl}${path}`, body, options).subscribe((res: any) => {
                observer.next(res?.data);
            });
        }).pipe(share());
    }

    put(path: string, body: any | null, options?: any): Observable<any> {
        return new Observable<any>((observer) => {
            this.http.put(`${baseUrl}${path}`, body, options).subscribe((res: any) => {
                observer.next(res?.data);
            });
        }).pipe(share());
    }

    delete(path: string, options?: any): Observable<any> {
        return new Observable<any>((observer) => {
            this.http.delete(`${baseUrl}${path}`, options).subscribe((res: any) => {
                observer.next(res?.data);
            });
        }).pipe(share());
    }
}