import { Injectable } from "@angular/core";
import { LoginResponse } from "src/app/auth/models/login-response";
import * as CryptoJS from 'crypto-js';

@Injectable({ providedIn: 'root' })
export class LocalStorageService {
    private readonly SECRET_KEY = '7B9FFDB381AB65667FEF77CA3BCEF';

    constructor() { }

    private encryptData(data: any): string {
        const encrypted = CryptoJS.AES.encrypt(JSON.stringify(data), this.SECRET_KEY);
        return encrypted.toString();
    }

    private decryptData(encryptedData: string): any {
        const bytes = CryptoJS.AES.decrypt(encryptedData, this.SECRET_KEY);
        const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
        return decryptedData;
    }

    getUserData(): LoginResponse | null {
        return this.getItem('userData');
    }

    setItem(name: string, obj: any) {
        const encryptedData = this.encryptData(obj);
        localStorage.setItem(name, encryptedData);
    }

    getItem(name: string) {
        let encryptedData = localStorage.getItem(name);
        if (encryptedData) {
            return this.decryptData(encryptedData);
        }
        return null;
    }

    removeItem(name: string) {
        localStorage.removeItem(name);
    }

    clear() {
        localStorage.clear();
    }
}