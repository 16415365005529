import { Component, ElementRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { MenuItem } from 'primeng/api';
import { LocalStorageService } from '../shared/services/localstorage.service';
import { LayoutService } from "./service/app.layout.service";
import { NgxPermissionsService } from 'ngx-permissions';
import { AuthService } from '../auth/services/auth.service';

@Component({
    selector: 'app-topbar',
    templateUrl: './app.topbar.component.html'
})
export class AppTopBarComponent {

    items!: MenuItem[];

    @ViewChild('menubutton') menuButton!: ElementRef;

    @ViewChild('topbarmenubutton') topbarMenuButton!: ElementRef;

    @ViewChild('topbarmenu') menu!: ElementRef;

    constructor(
        public layoutService: LayoutService,        
        private localstorageService: LocalStorageService,
        private permissionService:NgxPermissionsService,
        private authService: AuthService,
        private router: Router,
        ) { }

    logout(){
        this.localstorageService.clear();
        this.permissionService.flushPermissions();
        this.authService.loggedInUser.next(null);
        this.router.navigate(['/auth/login']);

    }
}
