import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { LoginResponse } from 'src/app/auth/models/login-response';

import { LocalStorageService } from './localstorage.service';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
    constructor(
        private localStorageService: LocalStorageService,
        private router: Router
    ) { }
    canActivate(): boolean | Promise<boolean> {
        let user: LoginResponse | null = this.localStorageService.getUserData();
        if (!user) {
            this.localStorageService.clear();
            this.router.navigate(['/auth/login']);
        }
        return true;
    }
}
