import { ChangeDetectorRef, OnInit } from '@angular/core';
import { Component } from '@angular/core';
import { LayoutService } from './service/app.layout.service';
import { AuthService } from '../auth/services/auth.service';
import { NgxPermissionsService } from 'ngx-permissions';

@Component({
    selector: 'app-menu',
    templateUrl: './app.menu.component.html'
})
export class AppMenuComponent implements OnInit {
    model: any[] = [];

    constructor(
        public layoutService: LayoutService,
        private permissionService:NgxPermissionsService,

        public authService: AuthService) { }

    ngOnInit() {
        this.authService.loggedInUser.subscribe((userData)=>{
            if(userData){
                this.permissionService.loadPermissions([userData.role]);
            }
        })
        this.model = [
            {
                label: 'KDM',
                items: [
                    { label: 'Dağıt', icon: 'pi pi-fw pi-send', routerLink: ['/kdm/distribute'] },
                    { label: 'Dağıtım Listeleri', icon: 'pi pi-fw pi-list', routerLink: ['/kdm/distribution-list'] }
                ],
                permission: ["admin","user"]
            },
            {
                label: 'Filmler',
                items: [
                    { label: 'Film Listesi', icon: 'pi pi-fw pi-list', routerLink: ['/film/list'] },
                    { label: 'Film Ekle', icon: 'pi pi-fw pi-plus', routerLink: ['/film/add'] }
                ],
                permission: ["admin","user"]
            },
            {
                label: 'Sinema',
                items: [
                    { label: 'Sinema Listesi', icon: 'pi pi-fw pi-list', routerLink: ['/cinema/list'] },
                    { label: 'Sinema Ekle', icon: 'pi pi-fw pi-plus', routerLink: ['/cinema/add'] }
                ],
                permission: ["admin","user"]
            },
            {
                label: 'Kullanıcılar',
                items: [
                    { label: 'Kullanıcı Listesi', icon: 'pi pi-fw pi-users', routerLink: ['/users/list'] },
                    { label: 'Kullanıcı Ekle', icon: 'pi pi-fw pi-user-plus', routerLink: ['/users/add'] }
                ],
                permission: ["admin"]
            }
        ];
    }
}
