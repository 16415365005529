import { ChangeDetectorRef, Component, HostListener, OnInit } from '@angular/core';
import { PrimeNGConfig } from 'primeng/api';
import { LayoutService } from './layout/service/app.layout.service';
import { LocalStorageService } from './shared/services/localstorage.service';
import { AuthService } from './auth/services/auth.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {
    public isDestop: any;

    constructor(
        private primengConfig: PrimeNGConfig, 
        private layoutService: LayoutService,
        private localStorageService: LocalStorageService,
        private authService: AuthService,
        private cdr: ChangeDetectorRef ) { 
        this.isDestop = window.innerWidth > 991;
        this.layoutService.setIsDesktopView(this.isDestop);
    }

    ngOnInit() {
        this.primengConfig.ripple = true;
        let userData = this.localStorageService.getUserData();
        this.authService.loggedInUser.next(userData);
        this.cdr.detectChanges();
    }

    @HostListener('window:resize', ['$event'])
    onResize() {
        this.isDestop = window.innerWidth > 991;
        this.layoutService.setIsDesktopView(this.isDestop);
    }
}
