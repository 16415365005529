import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { BaseCrudService } from 'src/app/shared/services/base-crud.service';
import { LoginRequest } from '../models/login-request';
import { LoginResponse } from '../models/login-response';


@Injectable({ providedIn: 'root' })
export class AuthService {
    loggedInUser = new BehaviorSubject<LoginResponse | null>(null);

    constructor(private crudService: BaseCrudService) {
    }

    login(req: LoginRequest): Observable<LoginResponse> {
        return this.crudService.post('/login', req);
    }
}
