import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { NotfoundComponent } from './pages/notfound/notfound.component';
import { AppLayoutComponent } from "./layout/app.layout.component";
import { AuthGuard } from './shared/services/auth.guard';

@NgModule({
    imports: [
        RouterModule.forRoot([
            {
                path: '',
                component: AppLayoutComponent,
                canActivate: [AuthGuard],
                children: [
                    { path: 'kdm', loadChildren: () => import('./pages/kdm/kdm.module').then(m => m.KdmModule) },
                    { path: 'film', loadChildren: () => import('./pages/film/film.module').then(m => m.FilmModule) },
                    { path: 'cinema', loadChildren: () => import('./pages/cinema/cinema.module').then(m => m.CinemaModule) },
                    { path: 'users', loadChildren: () => import('./pages/users/users.module').then(m => m.UsersModule) },
                ]
            },
            { path: 'auth', loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule) },
            { path: 'notfound', component: NotfoundComponent },
            { path: '**', redirectTo: '/notfound' },
        ], { scrollPositionRestoration: 'enabled', anchorScrolling: 'enabled', onSameUrlNavigation: 'reload' })
    ],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
